export class RoutesConstants {

  static appReferences: string = '/app-references';

  static privacyTerms: string = '/privacy-terms';

  static termsOfUse: string = '/terms-of-use';
  static plans: string = '/plans';
  static home: string = '/';
  static deeplink: string = '/deeplink';
}
export type TypeRoutesConstants = keyof typeof RoutesConstants;