import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesConstants } from "./routesConstants";

const RedirectHandler: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Captura a URL atual
        const urlParams = new URLSearchParams(window.location.search);
        const sharedInfoId = urlParams.get("sharedInfo/id");

        if (sharedInfoId) {
            // Verifica se o app está instalado
            const appUrl = `Wellny://sharedInfo/${sharedInfoId}`;

            window.location.href = appUrl;

            setTimeout(() => {
                // Caso o app não tenha sido aberto, redireciona para a home
                window.location.href = "/";
            }, 1000); // Timeout de 1 segundo para tentar abrir o app
        } else {
            // Caso não tenha o parâmetro, redireciona para a home
            navigate(RoutesConstants.home);
        }
    }, [navigate]);

    return null; // Não renderiza nada
};

export default RedirectHandler;